import React from 'react'
import {Container, Row, Col} from 'reactstrap';

import Layout from '../components/layout';
import SEO from '../components/seo';
import {Pricing} from "../components/pricing";
import {withPrefix} from "gatsby-link";
import MicroscopeImage from "../components/MicroscopeImage";
import PAPTestMedoboardImage from "../components/PAPTestImage";

const PAPTest = (props) => (
    <Layout>
        <SEO googleTitle="Medoboard - PAP-Test"
             googleDescription="Medoboard vereinfacht das Datenmanagement zum PAP-Test und optimiert die Patientenverwaltung damit signifikant."
             fbUrl="https://medoboard.com/pap-test"
             fbImage={withPrefix('medoboard-fb.png')}
             fbTitle="Medoboard - PAP-Test"
             fbDescription="Medoboard vereinfacht das Datenmanagement zum PAP-Test und optimiert die Patientenverwaltung damit signifikant."
             keywords={[`medoboard`, `Ordinationssoftware`, `intelligent`, `einfach`, `Patientenakte`, `Arztsoftware`, `PAP`, `Test`, `PAP-Test`]}/>
        <Container>
            <Row className="carousel">
                <Col md="12">
                    <MicroscopeImage />
                </Col>
            </Row>
            <Row>
                <Col md="12">
                    <h2 className="featurette-heading">PAP-Test</h2>
                    <h4 className="text-muted">Der PAP-Test ist eine Früherkennungsuntersuchung für Gebärmutterhalskrebs.</h4>
                    <p>
                        Im Rahmen einer gynäkologischen Untersuchung öffnet der Arzt vorsichtig die Scheide mit einem Spekulum, sodass er Zugang zum Muttermund hat.
                        Dort werden nun mit einer kleinen Bürste Zellen aus dem Gebärmutterhalskanal entnommen - das verläuft in der Regel schmerzfrei, für manche Patientinnen
                        ist das aber manchmal unangenehm.
                    </p>
                    <p>
                        Nach der Entnahme bringt der Arzt die Zellen auf einem Objektträger auf und schickt sie an ein Labor. Dort werden die entnommenen Zellen durch ein spezielles Verfahren sichtbar gemacht werden und können dann beurteilt werden.
                    </p>
                </Col>
            </Row>
            <hr className="featurette-divider" />
            <Row>
                <Col md="12">
                    <h2>Der PAP-Test in Medoboard</h2>
                </Col>
            </Row>
            <Row>
                <Col md="12">
                    <PAPTestMedoboardImage />
                </Col>
            </Row>
            <Row>
                <Col md="12">
                    <p>
                        Medoboard liefert auf einen Blick den Status des PAP-Tests zu jeder Visite. Damit haben Sie alle relevanten Informationen jederzeit im Blick.
                    </p>
                </Col>
            </Row>
            <p>
                Testen Sie medoboard noch heute kostenlos und unverbindich!
            </p>
            <hr className="featurette-divider"/>
            <Pricing/>
        </Container>
    </Layout>
);

export default PAPTest;
